import { getEnumKeyByEnumValue } from '@/helpers/enums';
import { createI18n } from 'vue-i18n';
import { getTranslations } from './api';

export enum Locale {
    EN = 'en',
    PT = 'pt',
}
export const fallbackLocale = Locale.EN;

export default async function initLocalization(forcedLanguage: string) {
    const locale = forcedLanguage || getBrowserLanguage() || fallbackLocale;

    const messages = {
        [locale]: await getTranslations(locale),
    };
    return createI18n({
        legacy: false,
        locale,
        fallbackLocale,
        messages,
    });
}

function getBrowserLanguage() {
    let locale = null;
    if (window.navigator.languages) {
        locale = window.navigator.languages
            .map((l) => l.slice(0, 2))
            .find((l) => getEnumKeyByEnumValue(Locale, l));
    } else if (window.navigator.language) {
        locale = window.navigator.language.slice(0, 2);
    }
    return locale;
}

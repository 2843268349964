<script setup lang="ts">
import {ref} from 'vue';
import {useFiltersStore} from '../../stores/filters';
import {useRouter} from 'vue-router';
import {storeToRefs} from 'pinia';
import {inIframe} from "@/iframe/utils";
import FilterHeader from "@/components/FeatureSearchFilters/FilterHeader.vue";


const filtersStore = useFiltersStore();
const {filters} = storeToRefs(filtersStore);
const router = useRouter();
const emit = defineEmits(['filterSelected', 'nextFilter', 'seeResults']);

const props = defineProps({
    value: {
        default: [],
    },
    index: Number
});

const options = [
    {
        name: 'important_feature.towing_capacity',
        value: 'towing-capacity',
        icon: 'trailer',
    },
    {
        name: 'important_feature.comfort',
        value: 'comfort',
        icon: 'feather',
    },
    {
        name: 'important_feature.safety',
        value: 'safety',
        icon: 'shield',
    },
    {
        name: 'important_feature.acceleration',
        value: 'acceleration',
        icon: 'speed',
    },
    {
        name: 'important_feature.space',
        value: 'space',
        icon: 'suitcase',
    },
    {
        name: 'important_feature.environment',
        value: 'environment',
        icon: 'environment',
    },
];
</script>

<template>
    <FilterHeader :title="$t('filter_important_feature.title')" :sub-title="$t('filter_common.sub_title')"
                  :index="index">
        <template #progress-observador>
            <slot name="progress-observador"/>
        </template>
    </FilterHeader>

    <div class="form__group" :class="{'search-by-features--observador': inIframe()}">
        <div class="row">
            <div
                class="col-6 col-md-4"
                v-for="(option, index) in options"
                :key="option.value"
            >
                <div class="selector">
                    <input
                        class="selector__input"
                        type="checkbox"
                        :id="'selectorCheckbox' + index"
                        name="selectorGroup"
                        v-model="filters['important-feature'].value"
                        :value="{
                            title: filters['important-feature'].toFormatted(
                                option.value
                            ),
                            value: option.value,
                        }"
                        :disabled="
                            !filters['important-feature'].value.find(
                                (e) => e.value == option.value
                            ) && filters['important-feature'].value.length >= 3
                        "
                    />
                    <label
                        class="selector__tile"
                        :for="'selectorCheckbox' + index"
                    >
                        <i :class="'as-icon-' + option.icon"></i>
                        <span class="selector__tile__label">{{
                                $t(option.name)
                            }}</span>
                    </label>
                </div>
            </div>

            <div class="col-12">
                <div
                    class="form__field_clear"
                    @click="
                        filters['important-feature'].value =
                            filters['important-feature'].defaultValue
                    "
                >
                    {{ $t('buttons.no_preference') }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.form__title {
    margin-bottom: 0 !important;
}
</style>

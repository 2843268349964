<script setup lang="ts">
import {ref, watch} from 'vue';
import {useFiltersStore} from '../../stores/filters';
import {storeToRefs} from 'pinia';
import {inIframe} from "@/iframe/utils";
import FilterHeader from "@/components/FeatureSearchFilters/FilterHeader.vue";

const filtersStore = useFiltersStore();
const {filters} = storeToRefs(filtersStore);

const props = defineProps({
    value: {
        default: '',
    },
    index: Number
});

const options = [
    {
        name: 'car_size.small',
        value: 'small',
        size: [2, 2],
    },
    {
        name: 'car_size.compact',
        value: 'compact',
        size: [5, 2],
    },
    {
        name: 'car_size.midsize',
        value: 'midsize',
        size: [5, 4],
    },
    {
        name: 'car_size.large',
        value: 'large',
        size: [7, 4],
    },
];
</script>

<template>
    <FilterHeader :title="$t('filter_car_size.title')" :sub-title="$t('filter_common.sub_title')" :index="index">
        <template #progress-observador>
            <slot name="progress-observador"/>
        </template>
    </FilterHeader>

    <div class="form__group" :class="{'search-by-features--observador': inIframe()}">
        <div class="row">
            <div
                class="col-6 col-md-3"
                v-for="(option, index) in options"
                :key="option.value"
            >
                <div class="selector">
                    <input
                        class="selector__input"
                        type="checkbox"
                        :id="'selectorRadio' + index"
                        name="selectorGroup"
                        v-model="filters['car-size'].value"
                        :value="{
                            title: filters['car-size'].toFormatted(
                                option.value
                            ),
                            value: option.value,
                        }"
                        :disabled="
                            !filters['car-size'].value.find(
                                (e) => e.value == option.value
                            ) && filters['car-size'].value.length >= 3
                        "
                    />
                    <label
                        class="selector__tile"
                        :for="'selectorRadio' + index"
                    >
                        <span class="selector__tile__label">{{
                                $t(option.name)
                            }}</span>
                        <div class="selector__tile__grouped_items">
                            <div>
                                <i class="as-icon-person"></i>
                                {{ option.size[0] }}
                            </div>
                            <div>
                                <i class="as-icon-suitcase"></i>
                                {{ option.size[1] }}
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <div class="col-12">
                <div
                    class="form__field_clear"
                    @click="
                        filters['car-size'].value =
                            filters['car-size'].defaultValue
                    "
                >
                    {{ $t('buttons.no_preference') }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

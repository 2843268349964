<script setup lang="ts">
import {ref, computed, watch} from 'vue';
import {useFiltersStore} from '../stores/filters';
import {useRouter, useRoute} from 'vue-router';
import ProgressIndicator from '../components/ProgressIndicator.vue';
import CarTypeFilter from '../components/FeatureSearchFilters/CarTypeFilter.vue';
import CarSizeFilter from '../components/FeatureSearchFilters/CarSizeFilter.vue';
import BudgetFilter from '../components/FeatureSearchFilters/BudgetFilter.vue';
import FuelTypeFilter from '../components/FeatureSearchFilters/FuelTypeFilter.vue';
import ImportantFeatureFilter from '../components/FeatureSearchFilters/ImportantFeatureFilter.vue';
import {useDataStore} from '../stores/data';
import {usePaginationStore} from '@/stores/pagination';
import {useSortsStore} from '@/stores/sorts';
import {useI18n} from 'vue-i18n';
import {useMeta} from 'vue-meta';

const store = useFiltersStore();
const dataStore = useDataStore();
const router = useRouter();
const route = useRoute();
const paginationStore = usePaginationStore();
const sortsStore = useSortsStore();
const filtersStore = useFiltersStore();

const {t} = useI18n();
useMeta({
    title: t('meta.search_by_feature__title'),
    description: t('meta.search_by_feature__description'),
});

const props = defineProps({
    isHomeView: {
        type: Boolean,
        default: false,
    },
});

interface SearchFilter {
    component: any;
    name: string;
    value: any;
}

const searchFilters: SearchFilter[] = [
    {
        component: CarTypeFilter,
        name: 'car-type',
        value: '',
    },
    {
        component: CarSizeFilter,
        name: 'car-size',
        value: '',
    },
    {
        component: BudgetFilter,
        name: 'budget',
        value: [
            dataStore.budgetRange.min,
            dataStore.budgetRange.defaultMax,
        ] as Array<number | string>,
    },
    {
        component: FuelTypeFilter,
        name: 'fuel-type',
        value: '',
    },
    {
        component: ImportantFeatureFilter,
        name: 'important-feature',
        value: '',
    },
];

let currentIndex = ref(1);
if (route.query.index != null) {
    currentIndex.value = Number(route.query.index);
}

const currentSearchFilter = computed(() => {
    return searchFilters[currentIndex.value - 1] ?? {};
});

function onFilterSelected(event: any) {
    searchFilters[currentIndex.value - 1].value = event;
}

function onSubmit() {
    updateIndex(currentIndex.value + 1);

    if (currentIndex.value == searchFilters.length + 1) {
        paginationStore.resetPagination();
        sortsStore.resetSorts();
        router.push({name: 'suggested-cars'});
    }
}

function updateIndex(newValue: any) {
    if (newValue < 1) currentIndex.value = 1;
    else currentIndex.value = newValue;
    router.push({
        query: {
            index: currentIndex.value,
        },
    });
}

watch(route, () => {
    const index = Number(route.query.index ?? 1);
    currentIndex.value = index;
});

function onNextFilter() {
    updateIndex(currentIndex.value + 1);
}

function onSeeResults() {
    router.push({name: 'suggested-cars'});
}
</script>

<template>
    <div class="container">
        <form class="form" @submit.prevent="onSubmit">
            <ProgressIndicator
                :isHomeView="isHomeView"
                :stepCount="searchFilters.length"
                :current-index="currentIndex"
                v-on:indicatorSelected="
                    (index) => {
                        updateIndex(index);
                    }
                "
            ></ProgressIndicator>
            <component
                v-if="currentSearchFilter.component"
                :is="currentSearchFilter.component"
                :value="currentSearchFilter.value"
                v-on:filterSelected="onFilterSelected"
                v-on:nextFilter="onNextFilter"
                v-on:seeResults="onSeeResults"
            ></component>
            <div
                :class="{
                    'form__buttons--multi': true,
                    'form__buttons--fixed--alternated': !isHomeView,
                    'form__buttons--multi--homeview': isHomeView,
                }"
            >
                <div class="container">
                    <a
                        v-if="currentIndex > 1"
                        class="button--left-arrow button--outline"
                        @click="updateIndex(currentIndex - 1)"
                    >{{ $t('buttons.previous') }}</a
                    >
                    <span
                        v-else
                        class="button--left-arrow button--outline is-disabled"
                    >
                        {{ $t('buttons.previous') }}
                    </span>
                    <button
                        type="submit"
                        class="button--right-arrow form__buttons__button_right"
                    >
                        {{
                            currentIndex == searchFilters.length
                                ? $t('buttons.see_results')
                                : $t('buttons.next')
                        }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

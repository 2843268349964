import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '../libraries/api/adapterMapping';
import {useDataStore} from "@/stores/data";

export const importantFeatureFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'important-feature',
    valueFormat: 'multiple',
    value: [],
    defaultValue: [],

    options: computed(() => []),

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },

    toFormatted: function (child: string): string {
        const { t } = useI18n();
        return t('important_feature.' + child.replace('-', '_'));
    },
    toUrlQueryFormatted: function (): string {
        let result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        }
    },
    hasValidValue: function (value): any {
        return useDataStore().importantFeatures.includes(value);
    },
    onValueChanged: function (): void { },
    toApiFilter: function (): ApiFilter[] {
        return [];
    },
    toApiSort: function (): string[] {
        return [];
    },
};

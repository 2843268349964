import autoselctrLogo from '@/assets/img/autoselectr-black.svg';
import produpressLogo from '@/assets/img/produpress-logo.png';
import produpressFrLogo from '@/assets/img/produpress-fr-logo.png';

export const brandingConfig: Record<string, { logo: string }> = {
    default: {
        logo: autoselctrLogo,
    },
    autoselectr: {
        logo: autoselctrLogo,
    },
    produpress: {
        logo: produpressLogo,
    },
    produpress_fr: {
        logo: produpressFrLogo,
    },
};

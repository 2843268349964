import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import {useDataStore} from "@/stores/data";

export const carSizeFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'car-size',
    value: [],
    defaultValue: [],
    valueFormat: 'multiple',
    options: computed(() => []),

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },

    toFormatted: function (child: string): string {
        const { t } = useI18n();
        return t('car_size.' + child.replace('-', '_'));
    },
    toUrlQueryFormatted: function (): string {
        let result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        }
    },
    hasValidValue: function (value): any {
        return useDataStore().carSizes.includes(value);
    },
    onValueChanged: function (): void { },
    toApiFilter: function (): ApiFilter[] {
        const VALUE_SCHEMA_MAPPING = new Map<string, string>([
            ['seating-capacity:small', '2'],
            ['seating-capacity:compact', '5'],
            ['seating-capacity:midsize', '5'],
            ['seating-capacity:large', '7'],
            ['luggage-size:small', '200'],
            ['luggage-size:compact', '400'], // (normal value is 200 but uses <= 400 as the condition)
            ['luggage-size:midsize', '400'],
            ['luggage-size:large', '400'],
        ]);

        const apiFilters: ApiFilter[] = [];

        for (const carSize of this.value) {
            apiFilters.push({
                attribute:
                    ATTRIBUTE_SCHEMA_MAPPING.get('seating-capacity') ??
                    'seating-capacity',
                operator: '=',
                value:
                    VALUE_SCHEMA_MAPPING.get(
                        ('seating-capacity:' + carSize.value) as string
                    ) ?? carSize.value,
            });

            apiFilters.push({
                attribute:
                    ATTRIBUTE_SCHEMA_MAPPING.get('luggage-size') ??
                    'luggage-size',
                operator: carSize.value == 'compact' ? '<=' : '>=',
                value:
                    VALUE_SCHEMA_MAPPING.get(
                        ('luggage-size:' + carSize.value) as string
                    ) ?? carSize.value,
            });
        }

        return apiFilters;
    },
    toApiSort: function (): string[] {
        return [];
    },
};

import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import {useDataStore} from "@/stores/data";

export const carTypeFilter: Filter<
    Array<{
        title: string;
        value: string;
    }>
> = {
    type: 'car-type',

    value: [],
    defaultValue: [],
    valueFormat: 'multiple',
    options: computed(() => []),

    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },
    toFormatted: function (child: string): string {
        const { t } = useI18n();
        return t('car_type.' + child.replace('-', '_'));
    },
    toUrlQueryFormatted: function (): string {
        let result: string[] = [];
        this.value.forEach((v) => {
            result.push(v.value);
        });
        return result.join(',');
    },
    toFilterFromUrlQuery: function (value): any {
        return {
            title: this.toFormatted(value),
            value: value,
        }
    },
    hasValidValue: function (value): any {
        return useDataStore().carTypes.includes(value);
    },
    onValueChanged: function (): void { },
    toApiFilter: function (): ApiFilter[] {
        const VALUE_SCHEMA_MAPPING = new Map<string, string>([
            ['sedan', 'SA,SH'],
            ['suv-crossover', 'CR,OD'],
            ['coupe', 'CO'],
            ['hatch-back', 'HA'],
            ['minivan', 'FW,MM'],
            ['station-wagon', 'CC,ES'],
            ['city-car', 'MC,4C'],
            ['convertible', 'CA,TA'],
        ]);

        const apiFilters: ApiFilter[] = [];

        for (const carType of this.value) {
            apiFilters.push({
                attribute: ATTRIBUTE_SCHEMA_MAPPING.get(this.type) ?? this.type,
                operator: 'in',
                value: VALUE_SCHEMA_MAPPING.get(carType.value) ?? carType.value,
            });
        }

        return apiFilters;
    },
    toApiSort: function (): string[] {
        return [];
    },
};

import type { ApiFilter, Filter } from '@/types';
import { computed } from 'vue';
import { ATTRIBUTE_SCHEMA_MAPPING } from '@/libraries/api/adapterMapping';
import {useDataStore} from "@/stores/data";

export const carBrandFilter: Filter<string> = {
    type: 'car-brand',
    value: '',
    defaultValue: '',
    valueFormat: 'single',
    options: computed(() => []),
    isDefault: function (): Boolean {
        return this.value == this.defaultValue;
    },
    toFormatted: function (): string {
        return this.value;
    },
    toUrlQueryFormatted: function (): string {
        return this.value;
    },
    toFilterFromUrlQuery: function (value): any {
        return value;
    },
    hasValidValue: function (value): any {
        return true;
    },
    onValueChanged: function (): void { },
    toApiFilter: function (): ApiFilter[] {
        return [
            {
                attribute: ATTRIBUTE_SCHEMA_MAPPING.get(this.type) ?? this.type,
                operator: '=',
                value: this.value,
            },
        ];
    },
    toApiSort: function (): string[] {
        return [];
    },
};

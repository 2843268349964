<script setup lang="ts">
import { useFiltersStore } from '@/stores/filters';
import { storeToRefs } from 'pinia';
import { inIframe } from '@/iframe/utils';
import FilterHeader from '@/components/FeatureSearchFilters/FilterHeader.vue';

const filtersStore = useFiltersStore();
const { filters } = storeToRefs(filtersStore);

const props = defineProps({
    value: {
        default: [],
    },
    index: Number,
});

const options = [
    {
        name: 'car_type.sedan',
        value: 'sedan',
        icon: 'sedan',
    },
    {
        name: 'car_type.suv',
        shortName: 'car_type.suv--short',
        value: 'suv-crossover',
        icon: 'suv',
    },
    {
        name: 'car_type.coupe',
        value: 'coupe',
        icon: 'coupe',
    },
    {
        name: 'car_type.city_car',
        value: 'city-car',
        icon: 'hatchback-small',
    },
    {
        name: 'car_type.minivan',
        value: 'minivan',
        icon: 'minivan',
    },
    {
        name: 'car_type.station_wagon',
        value: 'station-wagon',
        icon: 'station-wagon',
    },
    {
        name: 'car_type.hatch_back',
        value: 'hatch-back',
        icon: 'hatchback',
    },
    {
        name: 'car_type.convertible',
        value: 'convertible',
        icon: 'cabrio',
    },
];
</script>

<template>
    <FilterHeader
        :title="$t('filter_car_type.title')"
        :sub-title="$t('filter_common.sub_title')"
        :index="index"
    >
        <template #progress-observador>
            <slot name="progress-observador" />
        </template>
    </FilterHeader>

    <div
        class="form__group"
        :class="{ 'search-by-features--observador': inIframe() }"
    >
        <div class="row">
            <div
                class="col-6 col-sm-4 col-lg-3"
                v-for="(option, index) in options"
                :key="option.value"
            >
                <div class="selector">
                    <input
                        class="selector__input"
                        type="checkbox"
                        :id="'selectorRadio' + index"
                        name="selectorGroup"
                        v-model="filters['car-type'].value"
                        :value="{
                            title: filters['car-type'].toFormatted(
                                option.value
                            ),
                            value: option.value,
                        }"
                        :disabled="
                            !filters['car-type'].value.find(
                                (e) => e.value == option.value
                            ) && filters['car-type'].value.length >= 3
                        "
                    />
                    <label
                        class="selector__tile"
                        :for="'selectorRadio' + index"
                    >
                        <img :src="'/img/cartypes/' + option.icon + '.svg'" />
                        <span
                            class="selector__tile__label"
                            v-if="option.shortName"
                        >
                            <span class="selector__tile__short_text">{{
                                $t(option.shortName)
                            }}</span>
                            <span class="selector__tile__full_text">{{
                                $t(option.name)
                            }}</span>
                        </span>
                        <span class="selector__tile__label" v-else>{{
                            $t(option.name)
                        }}</span>
                    </label>
                </div>
            </div>

            <div class="col-12">
                <div
                    class="form__field_clear"
                    @click="
                        filters['car-type'].value =
                            filters['car-type'].defaultValue
                    "
                >
                    {{ $t('buttons.no_preference') }}
                </div>
            </div>
        </div>
    </div>
</template>

import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ObservadorHomeView from '../views/observador/ObservadorSearchByFeature.vue';
import {sendRouteChange, sendScrollTop} from '@/iframe/handler';
import { inIframe } from '@/iframe/utils';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: inIframe() ? ObservadorHomeView : HomeView,
        },
        {
            path: '/search-by-brand',
            name: 'search-by-brand',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                inIframe()
                    ? import('../views/observador/ObservadorSearchByBrand.vue')
                    : import('../views/SearchByBrandView.vue'),
        },
        {
            path: '/search-by-feature',
            name: 'search-by-feature',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                inIframe()
                    ? import(
                          '../views/observador/ObservadorSearchByFeature.vue'
                      )
                    : import('../views/SearchByFeatureView.vue'),
        },
        {
            path: '/suggested-cars',
            name: 'suggested-cars',
            props: (route) => ({
                isSearchByBrand:
                    router.options.history.state.back == '/search-by-brand' ||
                    router.options.history.state.back ==
                        '/observador/search-by-brand',
            }),
            component: () => import('../views/SuggestedCarsView.vue'),
        },
        {
            path: '/suggested-cars/versions/:url_key',
            name: 'suggested-cars-vehicles',
            component: () => import('../views/SuggestedCarsVehicleVersionsView.vue'),
            props: true,
        },
        {
            path: '/compare-cars',
            name: 'compare-cars',
            component: () => import('../views/CompareCarsView.vue'),
        },
        window.location.href.includes('localhost')
            ? {
                  path: '/styleguide',
                  name: 'styleguide',
                  component: () => import('../views/Styleguide.vue'),
              }
            : {
                  path: '/styleguide',
                  name: 'not-found',
                  // route level code-splitting
                  // this generates a separate chunk (About.[hash].js) for this route
                  // which is lazy-loaded when the route is visited.
                  component: () => import('../views/NotFound.vue'),
                  props: true,
              },
        {
            path: '/blog/:id',
            name: 'blog',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/BlogView.vue'),
        },
        {
            path: '/quote',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    path: 'request',
                    name: 'quote_request',
                    component: () => import('../views/QuoteRequest.vue'),
                },
                {
                    path: 'request-edp',
                    name: 'quote_request-edp',
                    component: () => import('../views/QuoteRequest.vue'),
                    props: { edp: true, comment: false },
                },
                {
                    path: 'success/:generic?',
                    name: 'quote_success',
                    component: () => import('../views/QuoteSuccess.vue'),
                    props: true,
                },
                {
                    path: 'error',
                    name: 'quote_error',
                    component: () => import('../views/QuoteError.vue'),
                },
            ],
        },
        {
            path: '/observador',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            children: [
                {
                    path: 'search-by-brand',
                    name: 'observador-search-by-brand',
                    component: () =>
                        import(
                            '../views/observador/ObservadorSearchByBrand.vue'
                        ),
                },
                {
                    path: 'search-by-feature',
                    name: 'observador-search-by-feature',
                    component: () =>
                        import(
                            '../views/observador/ObservadorSearchByFeature.vue'
                        ),
                },
            ],
        },
        {
            path: '/privacy-policy',
            name: 'privacy',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/PrivacyView.vue'),
        },
        {
            path: '/terms-and-conditions',
            name: 'tc',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/TermsView.vue'),
        },
        {
            path: '/vehicle/:url_key',
            name: 'car_details',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/CarDetailsView.vue'),
            props: true,
        },
        {
            path: '/:any(.*)',
            name: 'not-found',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/NotFound.vue'),
            props: true,
        },
    ],
});

router.beforeEach(() => {
    if (inIframe()) {
        sendScrollTop();
    }
})

router.afterEach(() => {
    if (inIframe()) {
        sendRouteChange(router.currentRoute.value.fullPath);
    } else {
        window.scrollTo(0, 0);
    }
});

export default router;

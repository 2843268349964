<script setup lang="ts">

import {useFiltersStore} from "@/stores/filters";

const filtersStore = useFiltersStore();

const props = defineProps({
    isPrimary: {
        type: Boolean,
        default: false
    }
})

</script>

<template>
    <div class="cta_box">
        <h2 class="cta_box__heading" v-if="!isPrimary">
            {{ $t('home.cta_box_title') }}
        </h2>
        <h2 class="cta_box__heading" v-else>
            {{ $t('home.lets_compare') }}
        </h2>
        <div class="cta_box__buttons">
            <RouterLink
                :to="{ name: 'search-by-brand' }"
                class="button--outline"
                :id="!isPrimary ? 'search-by-brand-secondary' : 'search-by-brand-primary'"
                @click="filtersStore.resetFilters()"
            >
                {{ $t('buttons.search_by_brand') }}
            </RouterLink>
            <RouterLink
                :to="{ name: 'search-by-feature' }"
                class="button--cta"
                :id="!isPrimary ? 'search-by-feature-secondary' : 'search-by-feature-primary'"
                @click="filtersStore.resetFilters()"
            >
                {{ $t('buttons.search_by_features') }}
            </RouterLink>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useDataStore} from '../../stores/data';
import {useFiltersStore} from '../../stores/filters';
import {formatPrice} from '../../helpers/utils';
import {storeToRefs} from 'pinia';
import {inIframe} from "@/iframe/utils";
import FilterHeader from "@/components/FeatureSearchFilters/FilterHeader.vue";

const filtersStore = useFiltersStore();
const dataStore = useDataStore();
const {filters} = storeToRefs(filtersStore);

const props = defineProps({
    value: {
        default: '',
        type: String,
    },
    index: Number
});
</script>
<style src="@vueform/slider/themes/default.css"></style>

<template>
    <FilterHeader :title="$t('filter_budget.title')" :sub-title="$t('filter_budget.sub_title')" :index="index">
        <template #progress-observador>
            <slot name="progress-observador"/>
        </template>
    </FilterHeader>

    <div class="slider__container" :class="{'search-by-features--observador': inIframe()}">
        <div class="slider__container">
            <range-slider
                class="slider multi-slider"
                :min="dataStore.budgetRange.min"
                :max="dataStore.budgetRange.max"
                :step="1000"
                v-model="filters['budget'].value"
                :tooltips="false"
                :lazy="false"
            />
            <span class="slider__tooltip">{{
                    formatPrice(filters['budget'].value[0])
                }}</span>
            <span class="slider__tooltip">{{
                    formatPrice(
                        filters['budget'].value[1],
                        false,
                        dataStore.budgetRange.max
                    )
                }}</span>
        </div>
    </div>
</template>

import {ref, computed, onMounted} from 'vue';
import {defineStore} from 'pinia';
import type {Filter, FilterType, SliderValues, Filters} from '@/types';
import {carTypeFilter} from '@/filters/carTypeFilter';
import {carSizeFilter} from '@/filters/carSizeFilter';
import {carBrandFilter} from '@/filters/carBrandFilter';
import {carModelFilter} from '@/filters/carModelFilter';
import {carVersionFilter} from '@/filters/carVersionFilter';
import {fuelTypeFilter} from '@/filters/fuelTypeFilter';
import {budgetFilter} from '@/filters/budgetFilter';
import {importantFeatureFilter} from '@/filters/importantFeatureFilter';
import {cloneDeep, filter} from "lodash";
import {useRouter} from "vue-router";
import {usePaginationStore} from "@/stores/pagination";

export const useFiltersStore = defineStore('filters', () => {
    const router = useRouter();
    const paginationStore = usePaginationStore();

    const filterTypePairs = {
        'car-type': carTypeFilter,
        'car-size': carSizeFilter,
        'car-brand': carBrandFilter,
        'car-model': carModelFilter,
        'car-version': carVersionFilter,
        'fuel-type': fuelTypeFilter,
        budget: budgetFilter,
        'important-feature': importantFeatureFilter
    }
    const filters = ref<Filters>(filterTypePairs);
    const filtersSelectedPopup = ref<Filters>(cloneDeep(filters.value));

    const filtersArray = computed(() =>
        usedFilterTypes.value.map((type) => {
            return {
                type,
                filter: filters.value[type],
            };
        })
    );
    const usedFilterTypes = computed(() =>
        Object.keys(filters.value).map((key) => (key = key as FilterType))
    );

    function setFiltersSelectedPopupWithFilters() {
        filtersSelectedPopup.value = cloneDeep(filters.value);
    }

    function setFiltersWithFiltersSelectedPopup() {
        for (const type of usedFilterTypes.value) {
            if (filtersSelectedPopup.value[type].value === null) {
                filtersSelectedPopup.value[type].value = filtersSelectedPopup.value[type].defaultValue;
            }
        }
        filters.value = cloneDeep(filtersSelectedPopup.value);

        setFiltersAsUrlQuery();
    }

    function setNullToDefaultValues() {
        for (const type of usedFilterTypes.value) {
            if (filters.value[type].value === null) {
                filters.value[type].value = filters.value[type].defaultValue;
            }
        }
    }

    function removeFilter(type: FilterType, value: string | SliderValues) {
        paginationStore.resetPagination();
        for (let filterObject of [filters, filtersSelectedPopup]) {
            if (filterObject.value[type] === undefined || filterObject.value[type].value === filterObject.value[type].defaultValue) {
                continue;
            }
            if (filterObject.value[type].valueFormat === 'single' || filterObject.value[type].valueFormat === 'slider') {
                filterObject.value[type].value = filterObject.value[type].defaultValue;
                filters.value[type].value = filterObject.value[type].value;
            } else if (filterObject.value[type].valueFormat === 'multiple') {
                const subFilters = filterObject.value[type].value as Array<{
                    title: string;
                    value: string;
                }>;
                filterObject.value[type].value = subFilters.filter(
                    (el) => el.value !== value
                );
                filters.value[type].value = filterObject.value[type].value;
            }
        }
        setFiltersAsUrlQuery();
    }

    function resetFilters() {
        paginationStore.resetPagination();
        usedFilterTypes.value.forEach((type) => {
            filters.value[type].value = filters.value[type].defaultValue;
        });
        setFiltersSelectedPopupWithFilters();
        setFiltersAsUrlQuery();
    }

    function handleFilterUrlQuery() {
        if (router.currentRoute.value?.query?.filters) {
            // If we have filter query in the url then transform these into filters
            transformUrlQueryToFilters();
        } else {
            // Else set url query with existing filters
            setFiltersAsUrlQuery();
        }
    }

    function getFilterUrlQuery() {
        return decodeURIComponent(<string>router.currentRoute.value.query.filters);
    }

    function getFiltersAsUrlQuery() {
        let queryParams: string[] = [];
        usedFilterTypes.value.forEach((type) => {
            let filter = filters.value[type];
            if (filter.value === "" || (Array.isArray(filter.value) && filter.value.length == 0) || filter.toUrlQueryFormatted() === "") return;
            queryParams.push(`${encodeURIComponent(type)}=${encodeURIComponent(filter.toUrlQueryFormatted())}`);
        });

        if (queryParams.length === 0) return {};
        return {filters: queryParams.join('&')};
    }

    function setFiltersAsUrlQuery() {
        router.push({query: getFiltersAsUrlQuery()});
    }

    function transformUrlQueryToFilters() {
        const filterParams: string = getFilterUrlQuery();

        filterParams.split('&').forEach((filterPair) => {
            const [filterType, filterValues] = filterPair.split('=');

            if (filterTypePairs.hasOwnProperty(filterType)) {
                const values = filterValues.split(',');

                values.forEach((value) => {
                    if (!filters.value[filterType as FilterType].hasValidValue(value)) return;

                    // If FilterType value is an array then push else set.
                    if (filters.value[filterType as FilterType].valueFormat === 'multiple') {
                        (filters.value[filterType as FilterType].value as Array<any>).push(filterTypePairs[filterType as FilterType].toFilterFromUrlQuery(value));
                    } else {
                        filters.value[filterType as FilterType].value = filterTypePairs[filterType as FilterType].toFilterFromUrlQuery(value);
                    }
                })
            }
        });
        setFiltersSelectedPopupWithFilters();
    }

    return {
        filters,
        filtersArray,
        removeFilter,
        resetFilters,
        filtersSelectedPopup,
        setFiltersSelectedPopupWithFilters,
        setFiltersWithFiltersSelectedPopup,
        setNullToDefaultValues,
        handleFilterUrlQuery,
        getFilterUrlQuery
    };
});

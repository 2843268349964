<script setup lang="ts">
import logo from '@/assets/img/autoselectr-white.svg';
</script>

<template>
    <!--Todo: @nienke add actual content and make it translatable-->
    <footer class="footer">
        <div class="container">
            <div class="row hidden-xs hidden-sm">
                <div class="col-12">
                    <a class="logo" href="/">
                        <img
                            :src="logo"
                            title="autoselectr"
                            alt="autoselectr"
                        />
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="footer__column">
                        <h4 class="hidden-md hidden-lg hidden-xl">
                            <a class="logo" href="/">
                                <img
                                    :src="logo"
                                    title="autoselectr"
                                    alt="autoselectr"
                                />
                            </a>
                        </h4>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="#">Car finder</a></li>
                            <li><a href="#">Blog</a></li>
                            <li>
                                <a href="/privacy-policy"
                                    >Privacy restrictions</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="footer__column">
                        <!--Todo: toggle ul on mobile (below 768px) by clicking on h4-->
                        <h4>Useful links</h4>
                        <ul>
                            <li><a href="#">Something else</a></li>
                            <li><a href="#">Athlos.com</a></li>
                            <li><a href="#">Observador.pt</a></li>
                        </ul>
                    </div>
                </div>
                <div
                    class="col-12 col-md-2 hidden-md hidden-sm hidden-xs"
                ></div>
                <div class="col-12 col-md-4 col-lg-3">
                    <!--Todo: toggle ul on mobile (below 768px) by clicking on h4-->
                    <h4>Contact info</h4>
                    <ul>
                        <!--Todo: @nienke add icons-->
                        <li>
                            <strong>Tel:</strong>
                            <a href="tel:456679675">+456 679 675</a>
                        </li>
                        <li>
                            <strong>Mail:</strong>
                            <a href="mailto:autoselector@gmail.com"
                                >autoselector@gmail.com</a
                            >
                        </li>
                    </ul>
                    <ul class="socials">
                        <!--Todo: @nienke replace with icons-->
                        <li><a href="">FB</a></li>
                        <li><a href="">TW</a></li>
                        <li><a href="">IG</a></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="footer__bottom">
                        &copy; {{ currentYear }} autoselector.com
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script lang="ts">
export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>
